/**==============
about
===============**/
#about{
    height: 80vh;
    width: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
   .content{
       height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    gap:20px;
    grid-template-columns: repeat(2, 1fr);
    img
    {
        width: 100%;
    }
    h2{
        color:$color-secondary;
        font-size: 45px;
        font-weight: 600;
        margin-bottom: 15px;
    }
p{
    color:$color-gray;
    text-align: justify;
    line-height: 32px;
    font-size: 17px;;
}
   }
}
@media screen and (max-width:1000px){
    #about{
        height: auto;
        margin: auto;

    
        
        .content{
            padding: 15px;
            grid-template-columns: auto;
            h2{
                font-size:35px;
                text-align: center;
            }
            img
            {
                width: 80%;
                margin: auto;
                display: block;
            }
        }
    }
}
@media screen and (min-width:901px) and (max-width:1200px){
    #about{
        padding-right:15px;
        padding-left:15px;
    }
}