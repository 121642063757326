/**==============
Team
===============**/
#team{
    margin-bottom: 40px;
    .title1{
        color:$color-secondary;
        text-align: center;
        font-size: 25px;
        font-weight: 400;
        margin-bottom: 15px;
    }
    .title2{
        color:$color-primary;
        text-align: center;
        font-size: 50px;
        font-weight: 400;
        margin-bottom: 15px;
    }
    .members{
        display: grid;
        gap:30px;
        margin:30px auto;
        grid-template-columns: repeat(3, 1fr);
        div{
            img{
                width: 100%;
                height: 500px;
                object-fit: cover;
            }
            h2{
                color:$color-secondary;
                font-weight: 400;
                text-align: center;
                margin: 10px auto;
            }
            p{
                color:$color-primary;
                text-align: center;
            }
        }
    }
}
 @media screen and (max-width:770px){
     #team{
         .title2{
             font-size:45px;
         }
         .members{
             grid-template-columns: auto;
         }
     }
 }