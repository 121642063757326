/**==============
Projects
===============**/
#projects{
    width: 100%;
    padding: 100px 0px;
    .title1, .title2{
        color:$color-secondary;
        font-size:20px;
        font-weight: 400;
        margin-bottom:5px;
    }
    .title2{
        color:white;
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 30px;
    }
    .container-projects{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap:20px;
        div{
            background-color: white;
            padding: 15px 20px;
            .icon{
                color:$color-secondary;
                display: block;
                font-size: 40px;
                text-align: center;
                margin-bottom: 10px;
            }
            h2{
                text-align: center;
                font-weight: 300;
                font-size:18px;
                line-height: 32px;
            }
        }
    }
}
@media screen and (max-width:770px){
    #projects{
        width: 90%;
        margin: auto;
        .container-projects{
            grid-template-columns: auto;
        }
    }
}
@media screen and (min-width:901px) and (max-width:1200px){
    #projects{
        padding-right:15px;
        padding-left:15px;
    }
}