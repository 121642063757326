/**==============
Contact Us
===============**/
#contact{
    height: auto;
    background-size: cover;
 padding: 50px 0px;
    .title1,.title2{
        color:$color-secondary;
        font-size:30px;
        text-align: center;
        margin-bottom: 8px;
    }
    .title2{
        color:$color-primary;
    }
    .content{
        display: grid;
        gap:20px;
        grid-template-columns: repeat(2, 1fr);
        margin:30px auto;

        img{
            width: 100%;
            border-radius:10px;
        }
        form{
            display: flex;
            flex-direction: column;
            label{
                margin-bottom: 10px;
            }
            .txt-red{
                color: red;
                padding-bottom: 10px;
                margin-bottom: 10px;
            }
            input, textarea{
                border:solid 3px $color-primary;
                border-radius: 5px;
                padding: 10px;
                margin-bottom: 10px;
                outline: none;
            }
            textarea{
                min-height: 200px;
            }
            button{
                background-color: $color-secondary;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $color-primary;
                padding: 10px;
                width: 50%;
                border:none;
                border-radius: 5px;
                font-size: 17px;
                transition: .5s ease all;
                .icon{
                    font-size:20px;
                    margin-right: 5px;
                }
            }
            button:hover{
                cursor: pointer;
                background-color: $color-primary;
                color: $color-secondary;
                
            }
        }
    }
}
/**==============
Media Queries
===============**/
@media screen and (max-width:770px){
    #contact{
        .content{
            grid-template-columns: auto;
        }
    }
}
@media screen and (min-width:901px) and (max-width:1200px){
    #contact{
        padding-right:15px;
        padding-left:15px;
    }
}