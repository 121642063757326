/**==============
Carousel services
===============**/
.carousel{
    max-width: 1200px;
  margin:50px auto;
  border:solid 1px red;

   
}
.boxService
{
    background-color: white;
 
    border-radius: 10px;
    border:solid 1px #cccccc;
    height: 600px;
    width: 150px;
    img{
        border-top-left-radius:  10px;
        border-top-right-radius:  10px;
        width: 100%;
        height: 250px;
        object-fit: cover;
    }
    h2{
        color:$color-primary!important;
        font-size: 25px!important;
        font-weight: 600;
        line-height: 34px;
        margin:10px;
    }
    p{
        color:#7A7A7A;
        line-height: 32px;
        font-weight: 400;
        text-align: justify;
        padding: 0px 15px;
    }
}
.slick-arrow{
    color:$color-primary!important;
    display: flex!important;
    background-color: #f2f2f6;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    z-index: 20;
    &:hover{
        background-color: $color-primary;
    }
}
.slick-list {margin: 0 -30px; }
.slick-slide>div {padding: 0 30px;}
.slick-prev {
    left: -40px;
  }
  .slick-next {
    right: -40px;
  }
.slick-dots{
   bottom: -40px;
}
.slick-dots li{
    width: 25px;
    height: 25px;
    color:$color-primary;
}
.slick-dots li button::before{
    font-size: 18px;
    color:$color-secondary;
    opacity: 1;
}
.slick-dots li.slick-active button::before {
    opacity: 1;
    color: $color-primary;
}
@media screen and (min-width:901px) and (max-width:1200px){
  
    .boxService
{
    height: 650px!important;
}
}