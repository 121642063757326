/**==============
Footer
===============**/
footer{
    background-color: $color-primary;
    padding: 20px;
    
    .content{
       
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        gap:5px;
        .detail{
            display: flex;
            align-items: center;
            p{
                color:white;
                line-height: 26px;
              
            }
            .icon{
                color:$color-secondary;
                margin-right:5px;
                font-size: 18px;
               
            }
        }
        .social-media{
            display: flex;
            align-items: center;
            .icon{
                color:$color-secondary;
                margin-right: 7px;
                border-radius: 50px!important;
                font-size: 20px;
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
    #arrow-top{
        color:$color-secondary;
        font-size: 40px;
        position: fixed;
        bottom: 10px;
        left: 10px;
        cursor:pointer;
        transition:0.5s all ease;
        &:hover{
            color:$color-gray;
        }
    }
    #containerchat{
        color:$color-secondary;
        font-size: 16px;
        position: fixed;
        border-radius:10px;
        padding: 10px;
        bottom: 53px;
        right: 10px;
        cursor:pointer;
        transition:0.5s all ease;
        &:hover{
            color:$color-gray;
        }
        #robot{
            color:$color-primary;
            font-size:40px;
        }
    }
   
    #chatbot{
        color:$color-secondary;
        font-size: 40px;
        position: fixed;
        bottom: 10px;
        right: 10px;
        cursor:pointer;
        transition:0.5s all ease;
        &:hover{
            color:$color-gray;
        }
    }
   
}
/**==============
MEDIA QUERIES
===============**/
@media screen and (max-width:1000px){
    footer{
        .content{
            grid-template-columns: auto;
            .detail{
                margin-bottom:15px;
            }
        }
    }
}