/**==============
Services
===============**/
#services{
    padding: 30px 0px;
    h2{
        color:$color-secondary;
        font-size: 50px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
    }
    .container_services{
        display: grid;
        gap:30px;
        grid-template-columns: repeat(3, 1fr);

        .box
        {
            background-color: white;
            box-shadow:0px 0px 3px rgba(0,0,0,0.5);
            border-radius: 5px;
            padding: 15px;
            img{
                width: 100%;
                height: 250px;
                object-fit: cover;
            }
            h2{
                color:$color-primary;
                font-size: 25px;
                font-weight: 600;
                line-height: 34px;
                margin:10px;
            }
            p{
                color:#7A7A7A;
                line-height: 32px;
                font-weight: 400;
                text-align: justify;
            }
        }
    }
}
/**==============
What We Offer
===============**/
#whatweoffer{
    margin:50px auto;
    h2{
        color:#29E7CD;
        font-size: 40px;
        text-align: center;
        margin-bottom: 30px;
    }
    p{
        color:$color-gray;
        line-height: 32px;
        margin-bottom: 20px;
    }
    ul{
        color: $color-gray;
        margin-bottom: 30px;
        padding-bottom: 40px;
        margin-left:20px;
        li{
            line-height: 32px;;
        }
    }
    button{
        background-color: $color-secondary;
        display: block;
        padding: 10px 15px;
        color:$color-primary;
        font-size:25px;
        text-align: center;
        margin:auto auto 30px auto;
        border:none;
        border-radius:50px;
        font-weight: 600;
        transition: 0.5s ease all;
    }
    button:hover{
        background-color: $color-primary;
        color:$color-secondary;
        cursor: pointer;
    }
}
/**==============
Whay we choose us
===============**/
#WhyChooseUs{
    margin:50px auto;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .image{
        display: block;
        margin: -80px auto auto;
    }
    .content{
        padding: 80px;
        h2{
            color:$color-secondary;
            font-size: 40px;
            font-weight: 400;
        }
        p{
            color:#C6C6C6;
            font-size: 20px;
            line-height: 32px;
            margin:20px auto;
            font-weight: 300;
        }
        div{
            display: flex;
            align-items: center;
            color:#C6C6C6;
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 20px;
            font-weight: 300;
            i{
                font-size: 40px;
                color:white;
                margin-right: 15px;
            }
        }
    }
}
@media screen and (max-width:770px){
    #services{
        width: 90%;
        margin: auto;
        h2{
            font-size: 40px;
        }
}
#WhyChooseUs{
    width: 100%;
    padding:15px;
    grid-template-columns: 100%;
    display: flex;
    .image{
        display: none;
    }
    .content{
   
        width: 100%;
        padding: 15px 15px 25px 15px;
    }
}
}
@media screen and (min-width:901px) and (max-width:1200px){
    #services, #whatweoffer{
        padding:15px;
    }
}