*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
$color-primary:#1C183F;
$color-secondary:#29E7CD;
$color-gray:#7A7A7A;

body{
    font-family: 'Roboto', sans-serif;
}

.bg-white{
    background-color: white;
}
.bg-blue{
    background-color: $color-primary;
}
.full-container{
    width: 100%;
}
.container{
    max-width:1200px;
    margin:auto;
}
.shadow{
    box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
}
.d-none{
    display: none!important;
}
.d-flex{
    display: flex;
}
.d-block{
    display: block!important;
}
.text-red{
    color:red;
}
.mb-1{
    margin-bottom: 5px;
}
.text-white{
 color:white;
}
.send-email{
    padding: 10px;
    border-radius: 5px;
    color:red;
    display: block;
    margin-bottom: 10px;
}

@media screen and (max-width:1000px){
    .container{
        padding: 10px;
    }
}