/**==============
header
===============**/
.bg-header{
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
    transition: 0.5s all ease;
    
}
header{
    position: fixed;
    width: 100%;
    z-index: 20;
    #menuMobile{
       position: fixed;
       top:10px;
       right:10px;
       font-size: 25px;
       color:$color-primary;

    }
    #menu{
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            max-width: 250px;
        }
        .menu-list{
            .active{
                border-bottom: $color-primary solid 2px;
                color:$color-primary;
            }
            a{
                border-bottom: solid 2px transparent;
                padding:0px 15px 4px 15px;
                color:$color-gray;
                transition: 0.4s ease all;
            }
            a:hover{
                color:#1C183F;
                border-bottom: solid 2px $color-primary;
                cursor: pointer;
            }
        }
    }

}

/**==============
home
===============**/
#home{
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    display: flex;
    .content{
       
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        margin-left:12%;
        
        .title1{
            color:white;
            font-size: 40px;
            font-weight: 600;
            letter-spacing: 1px;
            text-align: left;
            margin-bottom:15px;
        }
        .title2{
            color:$color-primary;
            font-size: 60px;
            font-weight: 600;
            letter-spacing: 1px;
            text-align: left;
            margin-bottom:15px;
        }
        .playButton{
            background-color: $color-primary;
            border-radius: 100px;           
            display: flex;
            justify-content: center;
            align-items: center;
            z-index:2;
            width: 70px;
            height: 70px;
            position: relative;
            .icon{
                color: $color-secondary;
                font-size: 35px;
            }
            &:hover{
                cursor:pointer;
            }
        }
        .playButton:before{
            content:'';
            position: absolute;
            border:solid 12px #C6C6C6;
            border-radius:50%;
           
            animation:bloom1 1.5s linear infinite;
            opacity: 0;
            z-index: 1;
        }
        .playButton:after{
            content:'';
            position: absolute;
            border:solid 12px #C6C6C6;
            border-radius:50%;
            top:-20px;
            left:-20px;
            right:-20px;
            bottom:-20px;
            animation:bloom2 1.15s linear infinite;
            opacity: 0;
            z-index: 1;
            animation-delay: 0.4s;
        }
        @keyframes bloom1 {
            0%{
                transform: (0.5);
                border:8px solid #C6C6C6;
                top:5px;
                left:5px;
                right:5px;
                bottom:5px;
            }
            50%{
               opacity:1;
            }
            100%{
                border:10px solid #C6C6C6;
                top:-20px;
                left:-20px;
                right:-20px;
                bottom:-20px;
                transform: (1.2);
                
            }
            
        }
    }
}
/**==============
video
===============**/
#video{
    display: none;
    background-color:rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top:0;
    left:0;
    z-index: 100;
    transition: 0.5s ease all;
    .containerVideo{
        width:100%;
        max-width: 800px;
        margin: auto;
        background-color: white;
        padding: 40px;
        border-radius: 5px;
        position: relative;
        #closeVideo{
            color:$color-secondary;
            position: absolute;
            top:10px;
            right:10px;
            font-size:30px;
            z-index: 10;
&:hover{
    cursor:pointer;
}
        }
        video{
            width: 100%;
        }
    }
}
/**==============
menu mobile
===============**/
.margin-right{
    margin-right:0!important;
}
#openMenuMobile{
    z-index: 99;
    position: fixed;
    top:10px;
    right:10px;
    font-size:30px;
    display: none;
    &:hover{
        cursor:pointer!important;
    }
}
#containerMobile{
    background-color:white;
    box-shadow:0px -3px 5px rgba(0,0,0,0.5);
    width: 250px;
    height: 100vh; 
    position: fixed;
    padding: 20px;
    top:0;
    right:0; 
    margin-right:-250px;
    transition: 0.5s ease all;
    z-index: 100;
    #closeMenuMobile{
        display: block;
     text-align: right;
     font-size:30px;
     color:$color-primary;
     transition: 0.2s ease all;
     
    }
    #closeMenuMobile:hover{
        color:$color-secondary;
        cursor:pointer!important;
     
    }
   
    .menu-list{
        margin-top:20px;
        display: flex;
        flex-direction: column;  
        a{
            margin-bottom: 10px;
            transition: 0.2s ease all;
            &:hover{
                color:$color-secondary;
                cursor:pointer;
            }
        }
    }
}
/**==============
media queries
===============**/
@media screen and (max-width:900px){
   
    header{
      display: none;
    }
    #openMenuMobile{
        display: block;
        right:20px;
       
    }
    #home{
        height: 100vh;
        .content{
            margin-left: 10px!important;
        
            width: 50%!important;
            .title1{
                font-size: 25px!important;
            }
            .title2{
                font-size:30px!important;
            }
            .playButton{
                width: 45px;
                height: 45px;
                .icon{
                    font-size:25px;
                }
            }
        }
       
    }
    #video{
        .containerVideo{
            height: 300px;
            padding: 20px;
            max-width: 90%;
            margin: auto;
            #frame{
                display: block;
                border:none;
                width: 100%;
                height: 100%;
            }
        }
    }

}
@media screen and (min-width:800px){
    #video{
        .containerVideo{
            padding: 20px;
            height: 500px;
            max-width: 800px;
            width: 100%;
            margin: auto;
       
            #frame{
                display: block;
                width: 100%;
                height: 100%;
                border:none;
            }
        }
    }
}
@media screen and (min-width:901px) and (max-width:1280px){
   
    header{
      display: block;
    }
    #openMenuMobile{
        display: none;
    }
    #home{
        height: 100vh;
        .content{
            margin-left: 5%;
        
            width: 40%!important;
            .title1{
                font-size: 35px!important;
            }
            .title2{
                font-size:50px!important;
            }
            .playButton{
                width: 70px;
                height: 70px;
                .icon{
                    font-size:35px;
                }
            }
        }
       
    }
    

}